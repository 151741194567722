import { useEffect, useState } from "react";
import axios from "axios";
import { saveUser } from "../../service/actions";
import { connect, useSelector, useDispatch } from "react-redux";
import { Navigate, useNavigate } from "react-router-dom";
import logo from '../assets/images/ast_white.png'
import { FaBeer } from 'react-icons/fa';
import Student from '../assets/images/students.png'
import Logo from '../assets/images/ast_white.png'
import TestIcon from '../assets/images/testss.png'
import SchoolIcon from '../assets/images/school.png'
import Ballar from '../assets/images/listss.png'
import Stat from '../assets/images/graphic.png'
import Zakovat from '../assets/images/zakovot.jpeg'
import TestSvg from '../assets/images/11.svg'
import BooksSvg from '../assets/images/22.svg'
import KirishBallar from '../assets/images/33.svg'
import Statistik from '../assets/images/44.svg'
import Univerce from '../assets/images/55.svg'
import { Link , useParams, useLocation} from "react-router-dom";
import {Dropdown, Breadcrumb} from 'react-bootstrap';
import {FaRegUserCircle} from 'react-icons/fa'
import Header2 from '../components/Header2'
import {FiChevronRight, FiCheckCircle, FiCheck, FiX} from 'react-icons/fi'
import { Language } from "../components/Languages";
const Dashboard = (props) =>{
    const dispatch = useDispatch()
    let navigate = useNavigate()
    let location = useLocation()
    const [list, setlist] = useState([])
    const [checkedItems, setCheckedItems] = useState([])
    const [checked, setChecked] = useState([]);
    const [oldClassId, setOldClassID] = useState(location.state.classid)
    const [themeName, setThemeName] = useState(location.state.name)
    const [started, setStarted] = useState(false)
    const [Testlar, setTestlar] = useState([])
    const users = localStorage.getItem('uid')
    const [Belgilangan, setBelgilangan] = useState([])
    const [SavollarId, setSavollarId] = useState([])
    const [Finish, setFinish] = useState(false)
    const [Natija, setNatija] = useState(null)
    const { id } = useParams();
    const [loading, setLoading] = useState(false) 
    const lang = localStorage.getItem('language')

    const langs = useSelector(state => state.langReducer)
    const langa = langs.lang

    const Icons = [
      "red","red","red","#ffcf4d","#3ae877","#00cf4c"
    ]
    const Baholar = [
      "Qoniqarsiz",
      "Qoniqarsiz",
      "Qoniqarsiz",
      "Qoniqarli",
      "Yaxshi",
      "A'lo"

    ]
    useEffect(() =>{ 
      SubjectList()
    },[])

    const SubjectList = (values) =>{
      setLoading(true)
      const headers = {
        'Content-Type': 'text/plain'
      };
  
      const datas = {
         token: 'c8d01d19fac584a20241873885363ff9',
         classesid: id
      }
  
      axios.post('https://astrontest.uz/mobile-api/api/uz/testthemeuz', datas, { headers })
        .then(response => {
          setLoading(false)
      //  alert(JSON.stringify(response.data))
        // console.log("AUTH", response.data)
        setlist(response.data)
        })
        .catch(error => {
          // console.log("error", error)
        })
    }

    const GetSavol = () =>{
      const headers = {
        'Content-Type': 'text/plain'
      };
  
      const datas = {
         token: 'c8d01d19fac584a20241873885363ff9',
         classid: id,
         subjectid: oldClassId,
         themeid: checked
      }

      // alert(JSON.stringify(datas))
  
      axios.post('https://astrontest.uz/mobile-api/api/uz/testlaruzfive', datas, { headers })
        .then(response => {
      //  alert(JSON.stringify(response.data))
        
        setTestlar(response.data)
        setStarted(true)
        })
        .catch(error => {
          console.log("error", error)
        })
    }
 

    const GetVariant = (savolid, varianid) => {
         
        let SelectedSavol = [...Belgilangan]
        SelectedSavol = [...Belgilangan, varianid]
        setBelgilangan(SelectedSavol)

        let SelectedVar = [...SavollarId]
        SelectedVar = [...SavollarId,savolid]
        setSavollarId(SelectedVar)
    }

    const CheckTest = () =>{
      setLoading(true)
      const headers = {
        'Content-Type': 'text/plain'
      };
  
      const datas = {
         token: users, 
         subjectid: oldClassId,
         themeid: checked,
         savolid: SavollarId,
         variantid: Belgilangan,
         user_id: 2,
         test_type: 2
      }

      // alert(JSON.stringify(datas))
  
      axios.post('https://astrontest.uz/mobile-api/api/uz/checktestfive', datas, { headers })
        .then(response => {
          setFinish(true)
      setLoading(false)

          setNatija(response.data)
      //  alert(JSON.stringify(response.data))
        
        
        })
        .catch(error => {
      setLoading(false)

          console.log("error", error)
        })
    }
    

    const handleCheck = (event) => {
      
      var updatedList = [...checked];
      if (event.target.checked) {
        if(checked.length < 5)
        {
          updatedList = [...checked, event.target.value];
        }
        
      } else {
        updatedList.splice(checked.indexOf(event.target.value), 1);
      }
      setChecked(updatedList);
    };

    var isChecked = (item) => 
    checked.includes(item) ? true : false;

    console.log('Belgilangan', Belgilangan)
    return(
        <div>
        
        <Header2 loading={loading}/>

        <div>
           {
            checked.length > 0 ? (
              <div style={{position:'fixed', top:95, right: 30}}>
                {
                  Finish ? (
                    null
                  ):(
                    started ? (
                      <div>
                        {
                          Object.keys(Belgilangan).length > 0 ? (
<button className="btn btn-success" onClick={() => CheckTest()}>{Language[langa].checkjavob} <FiCheckCircle style={{marginTop: -3}}/></button>
                          ):(
                            <button className="btn btn-secondary" disabled>{Language[langa].checkjavob} <FiCheckCircle style={{marginTop: -3}}/></button>
                          )
                        }
                       
                       <div className="mt-2 bg-light p-2 rounded">
                        <div className="d-flex align-items-center justify-content-between">{Language[langa].allquestion}: <b>{Testlar.length}</b></div>
                        <div className="d-flex align-items-center justify-content-between">{Language[langa].checked}: <b>{Object.keys(Belgilangan).length}</b></div>
                        <div className="d-flex align-items-center justify-content-between">{Language[langa].unchecked}: <b>{Testlar.length - Object.keys(Belgilangan).length}</b></div>
                       </div>
                      </div>
                    ):(
                      <button className="btn btn-warning" onClick={() => GetSavol()}>{Language[langa].start} <FiCheckCircle style={{marginTop: -3}}/></button>
                    )
                  )
                  
                }
                
              </div>
            ):(null)
           }
        </div>

        <div className="mt-4">
            <div className="container">
                <div className="row">
                  <div className="col-md-12">
                      <ul className="bread">
                      <li>
                        <Link to="/">{Language[langa].mainpage}</Link>
                        </li>
                        {/* <li>
                        <Link to="/subjects">Fanlar</Link>
                        </li> */}
                        {
                          Finish ? (
                            <></>
                          ) : (
                            <>
                            <Link to={'/classes/'+oldClassId+''}>{Language[langa].class}</Link>
                            <li><span className="mx-2">{themeName}</span></li>
                            </>
                          )
                        }
                        
                       
                      </ul>
                      
                      {
                        Finish ? (
                          <div className="mt-3">
                             <h5>{Language[langa].result}</h5>

                              <div className="shadow-sm p-3 rounded my-2 items">
                                <div className="row">
                                  <div className="col-md-6">
                                      <div className="row">
                                      <div className="col-md-6 d-flex"><div style={{width:90}}>{Language[langa].jami}:</div> <b className="bg-light p-1 rounded mx-3">{Natija.barcha_savollar}</b>  </div>
                                      <div className="col-md-6 d-flex"><div style={{width:90}}>{Language[langa].belgilandi}:</div> <b className="bg-light p-1 rounded mx-3">{Natija.belgilangan}</b>  </div>
                                      </div>
                                      <div className="row mt-3">
                                      <div className="col-md-6 d-flex"><div style={{width:90}}>{Language[langa].true}:</div> <b className="bg-light p-1 rounded mx-3">{Natija.truecount}</b>  </div>
                                      <div className="col-md-6 d-flex"><div style={{width:90}}>{Language[langa].foiz}:</div> <b className="bg-light p-1 rounded mx-3">{Natija.foiz}%</b>  </div>
                                      </div>
                                  </div>

                                  <div className="col-md-6">
                                     <div className="text-center mt-3">
                                         <div className="p-2 rounded" style={{background: Icons[Natija.baho], color:'#fff', fontWeight:'600'}}>{Baholar[Natija.baho]}</div>
                                     </div>
                                  </div>
                              </div>
                              </div>

                              <div className="mt-4">
                                 {
                                   Natija.savol.map((item, index) => {
                                    return(
                                      <div className="my-2" key={item.id}>
                                         <b>{item.savol}</b>
                                         <div>
                                            {
                                              item.test_file !== 'empty' ? (
                                              <img src={'https://astrontest.uz/mypage/uploads/'+item.test_file+''} style={{width: 160}}/>
                                              ) : (
                                                <></>
                                              )
                                            }
                                            
                                          </div>
                                         <div className="mt-1 d-flex">
                                            <div style={{background:'#00bf89', width: 22, height:22}} className="rounded d-flex justify-content-center align-items-center"><FiCheck color="#fff"/></div>
                                            <div className="mx-2">{item.variant[0].truev}</div>
                                         </div>
                                         <div className="mt-1 d-flex">
                                         <div style={{background:'#eb3636', width: 22, height:22}} className="rounded d-flex justify-content-center align-items-center"><FiX color="#fff"/></div>
                                          <div className="mx-2">{item.belgilangan[0].checked_variant}</div>
                                         </div>
                                      </div>
                                    )
                                   })
                                 }
                              </div>

                          </div>
                        ):(
                          started ? (
                            Testlar && Testlar.map((item, index) => {
                              return(
                                <div className="mt-3">
                              <div className="shadow-sm p-2 rounded my-2 items d-flex" style={{fontWeight:'600', flexDirection:'column'}}>
                                 <p>{index + 1}. {item.savol_nomi}</p>
                                  <div>
                                    {
                                      item.test_file !== 'empty' ? (
                                      <img src={'https://astrontest.uz/mypage/uploads/'+item.test_file+''} style={{width: 160}}/>
                                      ) : (
                                        <></>
                                      )
                                    }
                                    
                                  </div>
                              </div>
                              <div className="mt-3">
                                {
                                  item.variant.map((vars, invar) => {
                                    return(
                                      <div className="d-flex align-items-center my-2"> 
                                          <input 
                                          type={'radio'} 
                                          name={'variants'+item.savol_id+''}
                                          onClick={(e) => GetVariant(item.savol_id,e.target.value)}
                                          value={vars.var_id}/>
                                          <div className="mx-2" style={{marginTop: -3}}>{vars.variant}</div> 
                                      </div> 
                                    )
                                  })
                                }
                                 
                              </div>
                            </div>
                              )
                            })
                            
  
                          ):(
                          <div className="mt-3">
                          {
                            list && list.map((item, index) =>{
                              return(
                                
                                <div className="shadow-sm p-2 rounded my-2 items d-flex align-items-center justify-content-between" key={item.mavzu_id}>
                                  <div className="d-flex align-items-center">
                                   <input  
                                   id={item.mavzu_id}
                                   value={item.mavzu_id} type="checkbox" onChange={handleCheck}
                                   checked={isChecked(item.mavzu_id)}
                                  
                                   />
                                   {/* <span className={isChecked(item.mavzu_id)}>{item.mavzu_id}</span> */}
                                    <label htmlFor={item.mavzu_id} className='mx-2' style={{cursor:'pointer'}}>{item.mavzu_nomi}</label>

                                  </div>
                                  
                                  <FiChevronRight/>
                                </div>
                              )
                            })
                          }
                          
                          
                        </div>
                          )
                        )
                        
                      }

                      

                  </div>
                </div>
            </div> 
        </div> 
        </div>
    )
}

export default Dashboard