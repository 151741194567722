import { MENU_COUNT } from '../actiontypes'
//import changeLanguage from '../../components/Language';

const initial = {
    counts: {},
};

export default (state = initial, action) => {
    switch (action.type) {
        case MENU_COUNT:{
         //   changeLanguage.setLanguage(action.lang)
            return {...state, counts: action.counts};
        }
        default:
            return state;
    }
}
