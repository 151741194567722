import { useEffect, useState } from "react";
import axios from "axios";
import { saveUser } from "../../service/actions";
import { connect, useSelector, useDispatch } from "react-redux";
import { Navigate, useNavigate } from "react-router-dom";
import logo from '../assets/images/ast_white.png'
import { FaBeer } from 'react-icons/fa';
import Student from '../assets/images/students.png'
import Logo from '../assets/images/ast_white.png'
import TestIcon from '../assets/images/testss.png'
import SchoolIcon from '../assets/images/school.png'
import Ballar from '../assets/images/listss.png'
import Stat from '../assets/images/graphic.png'
import Zakovat from '../assets/images/zakovot.jpeg'
import TestSvg from '../assets/images/11.svg'
import BooksSvg from '../assets/images/22.svg'
import KirishBallar from '../assets/images/33.svg'
import Statistik from '../assets/images/44.svg'
import Univerce from '../assets/images/55.svg'
import { Link , useParams, createSearchParams} from "react-router-dom";
import { Language } from "../components/Languages";
import {Dropdown, Breadcrumb } from 'react-bootstrap';
import {FaRegUserCircle} from 'react-icons/fa'
import Header2 from '../components/Header2'
import {FiChevronRight} from 'react-icons/fi'
const Dashboard = (props) =>{
    const dispatch = useDispatch()
    let navigate = useNavigate()
    const [list, setlist] = useState([])
    const [loading, setLoading] = useState(false) 
    const langs = useSelector(state => state.langReducer)
    const langa = langs.lang
    const users = localStorage.getItem('uid')
    const { id } = useParams();
    useEffect(() =>{ 
      SubjectList()
      
    },[])

    const SubjectList = (values) =>{
      setLoading(true)
      const headers = {
        'Content-Type': 'text/plain'
      };
  
      const datas = {
         token: 'c8d01d19fac584a20241873885363ff9',
         subjectid: id
      }
  
      axios.post('https://astrontest.uz/mobile-api/api/uz/zmavzu', datas, { headers })
        .then(response => {
          setLoading(false)
      //  alert(JSON.stringify(response.data))
        // console.log("AUTH", response.data)
        setlist(response.data)
        })
        .catch(error => {
          setLoading(false)
          // console.log("error", error)
        })
    }
    return(
        <div>
        
        <Header2 loading={loading}/>

        <div className="mt-4">
            <div className="container">
                <div className="row">
                  <div className="col-md-12">
                      <ul className="bread">
                      <li>
                        <Link to="/">{Language[langa].mainpage}</Link>
                        </li>
                        <li>
                        <Link to="/zakovat">{Language[langa].manbaa}</Link>
                        </li>
                        {/* <li><span className="mx-2">Fanlar</span></li> */}
                      </ul>

                      <div className="mt-3">
                        {
                          list && list.map((item, index) =>{
                            return(
                              <div className="shadow-sm p-2 rounded my-2 items d-flex align-items-center justify-content-between" key={item.id}>
                                <Link style={{width:'100%'}}
                                to={'/savol/'+item.id+''} 
                                state={{
                                  name: item.mavzu,
                                  classid: id
                                }}
                                >{item.mavzu}</Link>
                                <FiChevronRight/>
                              </div>
                            )
                          })
                        }
                        
                        
                      </div>

                  </div>
                </div>
            </div> 
        </div> 
        </div>
    )
}

export default Dashboard