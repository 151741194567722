import { useEffect } from 'react';
import logo from './logo.svg';
import './App.css';
import {
  BrowserRouter as Router,
  Routes, 
  Route,
  Redirect
} from 'react-router-dom'
import PrivateRoutes from './src/components/Private'
import Homescreen from './src/containers/Home'
import Dashboard from './src/containers/Dashboard';
import Subject from './src/containers/Subjects'
import Classes from './src/containers/Classes'
import Themes from './src/containers/Themes'
import Tests from './src/containers/Tests'
import ZakovatManbaa from './src/containers/ZakovatManbaa'
import ZakovatMavzu from './src/containers/ZakovatMavzu'
import ZakovatSavol from './src/containers/ZakovatSavol'
import { setLocale } from 'yup';
import { LangContext } from './src/components/LangContext';
import { useSelector, connect, useDispatch } from 'react-redux'

function App() {

 useEffect(() => {
 
 }, [])

 const langs = useSelector(state => state.langReducer)
 const lang = langs.lang

  return (
   
     <Router>
       <Routes>
        
          {/* <Route element={<PrivateRoutes/>}> */}
             <Route index element={<Subject/>} path={'/'} />
             <Route element={<ZakovatManbaa/>} path='zakovat'/>
             <Route element={<Classes/>} path='classes/:id'/>
             <Route element={<ZakovatMavzu/>} path='mavzu/:id'/>
             <Route element={<ZakovatSavol/>} path='savol/:id'/>
             <Route element={<Themes/>} path='theme/:id'/>
             <Route element={<Themes/>} path='theme/:id'/>
             
          {/* </Route> */}
          {/* <Route index element={<Homescreen/>}/> */}
       </Routes>
     </Router>
    
  );
}

export default App;
