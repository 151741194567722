import { SAVE_COUNTRY } from '../actiontypes'
//import changeLanguage from '../../components/Language';

const initial = {
    countries: null,
};

export default (state = initial, action) => {
    switch (action.type) {
        case SAVE_COUNTRY:{
         //   changeLanguage.setLanguage(action.lang)
            return {...state, countries: action.countries};
        }
        default:
            return state;
    }
}
