import React from "react";

export const Language = {
  uz: {
     tests: "Testlar",
     mainpage: "Asosiy sahifa",
     class: "Sinflar",
     subject: "Fanlar",
     checkjavob: "Javobni tekshirish",
     allquestion: "Barcha savollar",
     checked: "Belgilangan",
     unchecked: "Belgilanmagan",
     start: "Testni boshlash",
     result: "Natijalar",
     jami: "Jami",
     belgilandi: "Belgilandi",
     true: "To'g'ri",
     foiz: "Foiz",
     zakovat: "Zakovat",
     manbaa: "Manbaa",
     savollar: "Savollar",
     view_answer: "Javobni ko'rish",
     hide_answer: "Javobni yashirish"
  },
  ru: {
    tests: "Тесты",
    mainpage: "Главная страница",
    class: "Классы",
    subject: "Предметы",
    checkjavob: "Проверка ответа",
    allquestion: "Все вопросы",
    checked: "Обозначается",
    unchecked: "Не отмечено",
    start: "Запуск теста",
    result: "Результаты",
    jami: "Итого",
    belgilandi: "Определено",
    true: "Правильно",
    foiz: "Процент",
    zakovat: "Заковат",
    manbaa: "Источник",
    savollar: "Вопросы",
    view_answer: "Посмотреть ответ",
    hide_answer: "Скрыть ответ"
  }
};
