import { SAVE_LANG } from '../actiontypes'
//import changeLanguage from '../../components/Language';

const initial = {
    lang: 'uz',
};

export default (state = initial, action) => {
    switch (action.type) {
        case SAVE_LANG:{
         //   changeLanguage.setLanguage(action.lang)
            return {...state, lang: action.lang};
        }
        default:
            return state;
    }
}
