import { useEffect } from "react";
import axios from "axios";
import { saveUser } from "../../service/actions";
import { connect, useSelector, useDispatch } from "react-redux";
import { Navigate, useNavigate } from "react-router-dom";
import {RiUser4Line, RiLockPasswordLine} from 'react-icons/ri'
import {FiAlertCircle} from 'react-icons/fi'
import 'bootstrap/dist/css/bootstrap.min.css';
import logo from '../assets/images/ast.png'
import { Formik , Form} from 'formik';
import * as Yup from 'yup'
const HomeScreen = () =>{
    const dispatch = useDispatch()
    let navigate = useNavigate()
    const Schema = Yup.object().shape({
 
    
        login: Yup.string()
           .required('Запомнить!'),
        
       
        parol: Yup.string()
           .required('Запомнить!'),
      
    })

    useEffect(() => {
      Enter()
     
    },[])

    const Enter = (values) =>{
        
        const headers = {
          'Content-Type': 'text/plain'
        };
    
        const datas = {
          getUniqueId: '123'
        } 
    
        axios.post('http://astrontest.uz/mobile-api/api/uz/webauth', datas, { headers })
          .then(response => {
          
           
         
          if(response.data.error == 200)
          {
            // dispatch(saveUser(response.data))
            localStorage.setItem('uid', response.data.user_id)
            navigate('/dashboard')
          }
          
    
          })
          .catch(error => {
            // console.log("error", error)
          })
      }
    return(
        <div className="index">
       <div className="indexbody">
       <Formik
          initialValues={{
            login: '',
            parol:''
            
        }}
          validationSchema={Schema}
          onSubmit={values => {
            Enter(values)
        }}
        onChange={() => {
          console.log('changing');
        }}
          >
       {({
               handleChange, handleBlur, handleSubmit, values, errors, touched
        }) => (
       <Form>
          <div className="form-index">
            <div className="text-center mb-2">
                <img src={logo} style={{width:'50%'}}/>
            </div>
            <div className="py-2" style={{position:'relative'}}>
            {errors.login && touched.login ? (
                    <div style={{ position:'absolute', right: 10, top: 16 }}>
                       <div style={{ color: 'red', fontSize: 11 }}><FiAlertCircle color="red" size={20}/></div>
                    </div>
                     ) : (
                         null
                      )

                  }
            <input type={'text'} className="form-control" placeholder="Login" style={{paddingLeft: 40}}
            onChange={handleChange} 
            onBlur={handleBlur} 
            name={'login'}
            value={values.login}
            />
            <div style={{position:'absolute', top: 12, left: 3}}>
                <div style={{width: 30, height: 30, background:'#636a7d', borderRadius: 6}} className="d-flex align-items-center justify-content-center"><RiUser4Line color="#fff"/></div>
            </div>
            </div>
            <div className="py-2" style={{position:'relative'}}>
                    {errors.parol && touched.parol ? (
                    <div style={{ position:'absolute', right: 10, top: 16 }}>
                       <div style={{ color: 'red', fontSize: 11 }}><FiAlertCircle color="red" size={20}/></div>
                    </div>
                     ) : (
                         null
                      )

                  }
            <input type={'password'} className="form-control" placeholder="Parol" style={{paddingLeft: 40}}
            onChange={handleChange} 
            onBlur={handleBlur} 
            name={'parol'}
            value={values.parol}
            />
            <div style={{position:'absolute', top: 12, left: 3}}>
                <div style={{width: 30, height: 30, background:'#636a7d', borderRadius: 6}} className="d-flex align-items-center justify-content-center"><RiLockPasswordLine color="#fff"/></div>
            </div>
            </div>
            <div className="py-2">
              <button className="btn btn-primary w-100"  onClick={handleSubmit}>Kirish</button>
            </div>
             
          </div>
          </Form>
        )}
      </Formik>

       </div>
       </div>
    )
}

export default HomeScreen