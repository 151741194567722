import { useEffect, useState } from "react";
import Dropdown from 'react-bootstrap/Dropdown';
import {FaRegUserCircle} from 'react-icons/fa'
import logo from '../assets/images/ast_white.png'
import {Link, NavLink} from 'react-router-dom'
import { Language } from "./Languages";
import { connect, useSelector } from 'react-redux'

// import { withTranslation } from 'react-i18next'
import { useTranslation, initReactI18next, Trans, withTranslation } from "react-i18next";
import { useDispatch } from "react-redux";
// import { SET_LANGUAGE } from "../service/constants"
import { saveLang } from '../service/actions'

function Header (props) {

    const dispatch = useDispatch();

    const langs = useSelector(state => state.langReducer)
    const langa = langs.lang

    const changeLanguage = (lang) => {
        
        dispatch(saveLang(lang));

    }

    useEffect(() => {
        
    }, [])

    

    return(
        <>
        {
            props.loading ? (
                <div style={{background:'rgba(255,255,255,0.7)', position:'absolute', left:0, right: 0, top: 0, bottom: 0, zIndex: 9999, display:'flex', justifyContent:'center', alignItems:'center'}}>
                    <div className="loadingio-spinner-eclipse-eh2g9mb2u6"><div className="ldio-zwq73kpvkih">
                <div></div>
                </div></div>
                </div>
            ) : <></>
        }
          
        <div className="header shadow">
            <div className="container-fluid">
                <div className="row">
                    <div className="col-md-2">
                    <Link to={'/'}><img src={logo} className="img-fluid"/></Link>
                    </div>
                    <div className="col-md-7">
                        <div className="topmenu d-flex align-items-center justify-content-center" style={{marginTop: 7}}>
                            <ul>
                                <li><NavLink to={'/'}> {Language[langa].tests}</NavLink></li>
                                <li><NavLink to={'/zakovat'}>{Language[langa].zakovat}</NavLink></li>
                                {/* <li><NavLink to={'/dashboard'}>Zakovat</NavLink></li> */}
                                {/* <li><NavLink to={'/dashboard'}>Statistika</NavLink></li> */}
                            </ul>
                        </div>
                    </div>
                    <div className="col-md-3">
                        <div className="justify-content-end d-flex">
                            {
                                langa == 'uz' ? (
                                    <button className="btn btn-sm btn-outline-light mx-2 active">UZ</button>
                                ) : (
                                    <button onClick={() => changeLanguage('uz')} className="btn btn-sm btn-outline-light mx-2">UZ</button>
                                )
                            }

                            {
                                langa == 'ru' ? (
                                    <button className="btn btn-sm btn-outline-light active">Рус</button>
                                ) : (
                                    <button onClick={() => changeLanguage('ru')} className="btn btn-sm btn-outline-light">Рус</button>
                                )
                            }
                            
                            

                        {/* <Dropdown>
                            <Dropdown.Toggle variant="light" id="dropdown-basic">
                                <FaRegUserCircle color="#333"/> Guest
                            </Dropdown.Toggle>

                            <Dropdown.Menu>
                                <Dropdown.Item href="#/action-1">Action</Dropdown.Item>
                                <Dropdown.Item href="#/action-2">Another action</Dropdown.Item>
                                <Dropdown.Item href="#/action-3">Something else</Dropdown.Item>
                            </Dropdown.Menu>
                            </Dropdown> */}
                        </div>
                    </div>
                </div>

            </div>
        </div>
        </>
    )
}

 
const mapStateToProps = (state, ownProps) => {
    return {
        
        // currentLangCode: state.language.lang
         
    }
}

Header = connect(mapStateToProps)(Header)
export default withTranslation('main')(Header)