import { useEffect, useState } from "react";
import axios from "axios";
import { saveUser } from "../../service/actions";
import { connect, useSelector, useDispatch } from "react-redux";
import { Navigate, useNavigate } from "react-router-dom";
import logo from '../assets/images/ast_white.png'
import { FaBeer } from 'react-icons/fa';
import Student from '../assets/images/students.png'
import Logo from '../assets/images/ast_white.png'
import TestIcon from '../assets/images/testss.png'
import SchoolIcon from '../assets/images/school.png'
import Ballar from '../assets/images/listss.png'
import Stat from '../assets/images/graphic.png'
import Zakovat from '../assets/images/zakovot.jpeg'
import TestSvg from '../assets/images/11.svg'
import BooksSvg from '../assets/images/22.svg'
import KirishBallar from '../assets/images/33.svg'
import Statistik from '../assets/images/44.svg'
import Univerce from '../assets/images/55.svg'
import { Link } from "react-router-dom";
import {Dropdown, Breadcrumb } from 'react-bootstrap';
import {FaRegUserCircle} from 'react-icons/fa'
import Header2 from '../components/Header2'
import {FiChevronRight} from 'react-icons/fi'
import { Language } from "../components/Languages";
// import { withTranslation, useTranslation } from 'react-i18next';
// import i18next from 'i18next';
import { useTranslation, initReactI18next, Trans, withTranslation } from "react-i18next";
import { Helmet } from "react-helmet"
// import {Adsense} from '@ctrl/react-adsense';
const Dashboard = (props) =>{
    const dispatch = useDispatch()
    let navigate = useNavigate()
    const [list, setlist] = useState([])
    const [loading, setLoading] = useState(false) 
    // const lang = localStorage.getItem('language')

    const langs = useSelector(state => state.langReducer)
    const langa = langs.lang

    const users =  localStorage.getItem('uid') 

    const [count, setCount] = useState(0);

    const appendYaMetrix = () => {
      const yaDiv = document.createElement('div')
      yaDiv.setAttribute('id', 'yandex_rtb_R-A-2384478-2')
      document.body.appendChild(yaDiv)
    
      const yaScript = document.createElement('script')
      yaScript.setAttribute('type', 'text/javascript')
      yaScript.innerHTML = `(function(w, d, n, s, t) {
            w[n] = w[n] || [];
            w[n].push(function() {
                Ya.Context.AdvManager.render({
                    blockId: "R-A-2384478-2",
                    renderTo: "yandex_rtb_R-A-2384478-2",
                    horizontalAlign: false,
                    async: true
                });
            });
            t = d.getElementsByTagName("script")[0];
            s = d.createElement("script");
            s.type = "text/javascript";
            s.src = "//an.yandex.ru/system/context.js";
            s.async = true;
            t.parentNode.insertBefore(s, t);
        })(this, this.document, "yandexContextAsyncCallbacks");`
    
        document.head.appendChild(yaScript)
    }
 
    
    useEffect(() =>{
      SubjectList()

      appendYaMetrix()

      
       
      // i18n.changeLanguage('ru')
    },[])

   

    // i18n.changeLanguage('en-US');

    const SubjectList = (values) =>{

      setLoading(true)
    
      const headers = {
        'Content-Type': 'text/plain'
      };
  
      const datas = {
         token: 'c8d01d19fac584a20241873885363ff9'
      }
  
      axios.post('https://astrontest.uz/mobile-api/api/uz/subjectuz', datas, { headers })
        .then(response => {

          setLoading(false)
         
        // console.log("AUTH", response.data)
        setlist(response.data)
        })
        .catch(error => {
          // console.log("error", error)
          setLoading(false)
        })
    }
    return(
        <div>
        
        <Header2 loading={loading}/>

        {/* <Adsense
          client="ca-pub-1156318250534352"
          slot="7259870550"
          style={{ display: 'block' }}
          layout="in-article"
          format="fluid"
        />  */}

        <div className="mt-4">
            <div className="container">
                <div className="row">
                  <div className="col-md-12">
                      <ul className="bread">
                        <li>
                        <Link to="/">{Language[langa].mainpage}</Link>
                        </li>
                        <li><span className="mx-2">{Language[langa].subject}</span></li>
                      </ul>

                      <div className="mt-3">
                        {
                          list && list.map((item, index) =>{
                            return(
                              <div className="shadow-sm p-2 rounded my-2 items d-flex align-items-center justify-content-between" key={item.subject_id}>
                                <Link to={'/classes/'+item.subject_id+''} style={{display: 'block', width:'100%'}}>{item.subject_name}</Link>
                                <FiChevronRight/>
                              </div>
                            )
                          })
                        }
                        
                        
                      </div>

                  </div>
                </div>
            </div> 
        </div>

        <div id="yandex_rtb_C-A-2384478-1"></div>

        {/* <Helmet>
     
      </Helmet> */}
      
        </div>
    )
}

export default withTranslation('main')(Dashboard);