import { createStore ,combineReducers } from 'redux';
import {reactLocalStorage} from 'reactjs-localstorage'; 

/* ------------- Reducers ------------- */
 
import userReducer from "./reducers/user"
import langReducer from './reducers/language'
import countryReducer from './reducers/countries'
import menucounts from './reducers/menucounts';

import users from './reducers/user'
import langs from './reducers/language'

const rootReducer = combineReducers({
  userReducer,
  langReducer,
  countryReducer,
  menucounts
});

function saveToLocalStorage(state) {
    try {
      const serialisedState = JSON.stringify(state);
      // sessionStorage.setItem("persistantState", serialisedState);
      reactLocalStorage.set("persistantState", serialisedState)
    } catch (e) {
      console.warn(e);
    }
  }
  
  function loadFromLocalStorage() {
    try {
      // const serialisedState = sessionStorage.getItem("persistantState");
      const serialisedState = reactLocalStorage.get("persistantState")
      if (serialisedState === null) return undefined;
      return JSON.parse(serialisedState);
    } catch (e) {
      console.warn(e);
      return undefined;
    }
  }

  
   
  const store = createStore(rootReducer, loadFromLocalStorage());
  
  
  store.subscribe(() => saveToLocalStorage(store.getState()));
  
  export default store;


 
