import {SET_LANGUAGE,SAVE_USER, SAVE_LANG} from "../actiontypes"
 

export const saveUser = user => ({
    type:SAVE_USER,
    user
   
})

export const saveLang = lang => ({
    type:SAVE_LANG,
    lang,
})